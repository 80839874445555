import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
// import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"
import * as containerStyles from "components/blog/blog.module.css"
import { HubSpotFormComponent } from "../../components/hubspot-form-component"

const TwitterDm = ({ location }) => {
  const title =
    "DMの一括配信ツールを使って、Twitterキャンペーンを手間なく実施しましょう。最短で当日導入できます。"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="TwitterのDMを一斉送信するツールを使って、キャンペーンの実施にかかる手間を削減"
          pagedesc="TwitterDM一斉送信を行えるツールの紹介と、ツールを使うメリットについて説明します。また、有料のツールを使う代わりにエクセルの関数を利用した小技も紹介します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-dm/twitter-dm.jpg"
          pagepath="/blog/twitter-dm"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              DMの一括配信ツールを使って、Twitterキャンペーンを手間なく実施しましょう。最短で当日導入できます。
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">2022年10月11日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="タブレットで文字を入力している人"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-dm/twitter-dm.jpg"
                className="w-full"
              />
            </figure>
            <div>
              <h2 id="id_twitter_campaign">
                Twitterのプレゼントキャンペーンは効果が非常に高い人気施策
              </h2>
              <p>
                <strong>
                  DM一括送信機能について詳しく説明が欲しい場合はお問い合わせください。
                </strong>
                <ul>
                  <li className={containerStyles.noBullet}>
                    電話で問い合わせる→
                    <a
                      href="tel:050-3528-8481"
                      className={containerStyles.superBlue}
                      style={{
                        color: "blue",
                        fontSize: "16px",
                        textDecoration: "underline",
                      }}
                    >
                      050-3528-8481
                    </a>
                  </li>
                  <li className={containerStyles.noBullet}>
                    まずは資料請求→
                    <a
                      href="https://hub.park-lot.com/document-download"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        color: "blue",
                        fontSize: "16px",
                        textDecoration: "underline",
                      }}
                    >
                      資料請求はコチラ
                    </a>
                  </li>
                </ul>
              </p>
              {/* <div className="case_study main">
                <CvBtn />
              </div> */}
              <p>申込みから、最短一日で利用が可能です。</p>
              <p>
                <h3 id="id_campaign_cost">
                  SNSを活用したキャンペーン実施にはDM送信などの手間がかかる、解決策は？
                </h3>
                インスタントウィンを始めとしたTwitterのプレゼントキャンペーンは、とても効果が高く、人気のキャンペーン施策です。
                しかし、SNSを活用したキャンペーンは、
                <u>当選者とのDMのやり取り</u>
                や景品の購入・発送処理などに多大な手間がかかります。
              </p>
              <p>
                <h3 id="id_campaign_tool">
                  ツールを活用して効率よくSNSキャンペーンを実施しましょう！
                </h3>
                効果があるのは分かっていても、抽選や住所の収集、景品の発送にかかる手間のことを考えると、なかなか実施に踏み切れない！
                というSNS運用の担当者様も多くいらっしゃるのではないでしょうか。
              </p>
              <p>
                今日は、
                <u>
                  TwitterのDMでギフトコードや住所入力フォームを当選者に簡単に一斉送信ができるツール
                </u>
                <br />
                さらに、<u>DM送信履歴の管理までできる便利なツール</u>
                についても紹介します。
              </p>
              <p>
                ツールを利用して、SNSキャンペーンを手間なく効率的に実施して、成功を掴みましょう。
              </p>
            </div>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitterウェブ版にもアプリ版にもDMを一括配信する仕組みはない？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    【実施前の確認】TwitterDMを一括配信する際の注意点
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1. TwitterDMの一斉送信ツールを提供している事業者に頼む
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    TwitterDMの一括配信、一斉送信機能を提供しているサービス
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    2. Twitter API を活用したプログラムを自作する
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    【番外編】エクセルで送信相手のDMリンクを自動で開けるように関数を仕込む
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id7" className="toc__link">
                    終わりに
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <p>
                今回の記事では「キャンペーン当選者にギフトコード・クーポンコードなど配布する際、
                メッセージの一部分をユーザーによってカスタマイズして配信する」方法について説明します。
              </p>
            </div>
            <div>
              <h2 id="id1">
                Twitterウェブ版にもアプリ版にもDMを一括配信する仕組みはない？
              </h2>
              <p>
                <strong>「twitter DM 一斉送信」</strong>
                <strong>「twitter DM 自動化」</strong>
                などのキーワードでネットを検索すると、「同じ内容のテキスト」を「複数のユーザーに配信する」方法が出てくることがあります。
              </p>
              <p>
                しかし、この方式では「抽選で当たった100名にAmazonギフトコードを送る」ような場合には活用できません。１件１件送信するテキストの内容を変えることができないからです。
              </p>
              <p>
                当然、Twitterの標準機能にも、このような「１件１件送信するテキストの内容を変えて送信する」という需要に応える機能は存在ません。
              </p>
              <p>
                そのため、「ユーザーごとに少しだけ異なる内容のDMを大量に送りたい」場合は、頑張って一通ずつ手作業で行うしかありません。
                ですが、このような作業を効率的に行うための方法が存在します。
              </p>
              <p>
                今回は、便利なツールを使うことで手作業でのDM送信をしなくて済む方法についてお伝えしたいと思います。
              </p>
              {/* <h2 id="id_twitter_dm_point"> */}
              <h2 id="id2">
                【実施前の確認】TwitterDMを一括配信する際の注意点
              </h2>
              <p>
                <h3 id="id_twitter_dm_point">
                  そもそも、DM一括送信する行為にリスクはないのか？
                </h3>
                Twitterルールに反してDMを自動で一方的に送ると、最悪の場合アカウントが凍結されてしまい、アカウントの利用ができなくなる可能性があります。
                利用の際はTwitterルールをよく読んでルールを守って利用するようにしましょう。
              </p>
              Twitterが開発者向けに提供しているAPI自体に、DMを自動送信するための機能が用意されています。
              このことからも、ルールを守って利用すれば問題ないことがわかると思います。
              <small style={{ color: "gray" }}>
                注：(APIとは、Twitterを企業や個人がシステムと連動して利用するためにTwitterが用意した仕組みのことです)
              </small>
              <p></p>
              <p>
                Twitterの自動化ルールについては
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-automation"
                  target="_blank"
                  rel="noreferrer"
                >
                  こちら
                </a>
                から確認できます。
              </p>
              <p>
                <h3 id="id_twitter_dm_point">
                  Twitter上での自動化したキャンペーンの実施は認められていると解釈できる
                </h3>
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-automation"
                  target="_blank"
                  rel="noreferrer"
                >
                  Twitterの自動化ルール
                </a>
                を一部抜粋してご紹介すると、「すべきこと」の項目に下記のような記載があります。
                <ol className="blog__border-list">
                  <li>
                    自社のコンテンツに反応したアカウントに自動返信するクリエイティブキャンペーンを実施する。
                  </li>
                  <li>
                    ダイレクトメッセージで利用者に自動返信するソリューションを開発する。
                  </li>
                  <li>
                    利用者の役に立つ新しいサービスに挑戦する（ただし、Twitterのルールを遵守する）。
                  </li>
                </ol>
                そのため、DMの一括自動送信もスパムのような目的や内容でなければ、問題ないと考えられます。
              </p>
              <p>
                <h3 id="id_twitter_dm_point">
                  Twitterでリツイートキャンペーン等を実施する際はガイドラインを守る
                </h3>
                <p>
                  Twitterのキャンペーンの実施についてのガイドラインについては
                  <a
                    href="https://help.twitter.com/ja/rules-and-policies/twitter-contest-rules"
                    target="_blank"
                    rel="noreferrer"
                  >
                    こちら
                  </a>
                  から確認できます。
                </p>
                <p>
                  どうしても心配な場合は、Twitterキャンペーンに詳しい広告代理店や、Twitter本社の担当者に直接問い合わせることを検討してもよいかもしれません。
                </p>
              </p>
            </div>
            <div>
              <h2 id="id3">
                1. Twitterの一括DM配信ツールを提供している事業者に頼む
              </h2>
              <p>一番手間がかからずに済むオススメの方法です。</p>
              <p>
                費用は少しかかりますが、TwitterのDMを一括配信する仕組みを提供している事業者に頼むのが一番時間の節約になるでしょう。一度ツール利用の契約を結べば、指定した内容をツールを通じて送信してくれます。
              </p>
              <p>
                何度も繰り返しプレゼントキャンペーンを実施する場合などは、このようなツールを利用している業者に頼むのがオススメです。
              </p>
              <h3 id="id_tairyo_haihu">
                ツール利用をすれば大量配布キャンペーンでもたった数分で作業完了！
              </h3>
              <p>
                「アマゾンギフト券やQUOカードPayが1000名に当たるキャンペーン」など、大量にプレゼントを配布するタイプのキャンペーンを実施する際は、
                手作業でやるにはあまりにも手間がかかりますのでツールの利用を検討すべきでしょう。
              </p>
              <p>
                一通一通、慎重に作業をすれば100通くらいであればなんとか送信できますが、1000通となると、よほどの集中力がなければケアレスミスなしでの業務遂行は不可能に近いです。
              </p>
              <p>
                仮に、一通送信するのに40秒かかったとして、1000通だと40000秒かかる計算になります。これを時間に換算すると、10時間以上かかる計算です。
              </p>
              <h3>
                ツールを使うメリットは手間が減って時間が節約になること以外にもある
              </h3>
              <ol className="blog__border-list">
                <li>作業時間を削減できる</li>
                <li>ミスを減らせる</li>
                <li>作業ログが残せる</li>
                <li>アカウント運用の安心感を得られる</li>
              </ol>
              <h3 id="id_manual">
                手作業にはミスがつきもの、時間もかかる・・・できるだけ手作業は避けましょう
              </h3>
              <p>
                手作業で実施したときに怖いのはケアレスミスと、ミスをしたことによる信用低下です。
                <br />
                よくあるミスとしては下記のようなものがあります。
              </p>
              <ol className="blog__border-list">
                <li>同じコードを配布してしまった</li>
                <li>
                  どのユーザーに送ったか分からなくなってコードが余ってしまった
                </li>
                <li>送信のログが取れないため履歴を追えない</li>
              </ol>
              <p>
                手作業で大量にDM送信処理を行った場合、上記のようなミスが発生してしまう可能性は高いでしょう。
                <br />
                ツールを使うことで、このような運用上のトラブルを防ぎ、時間という貴重なリソースを節約することができます。
              </p>
            </div>
            <div>
              <h2 id="id4">
                TwitterDMの一括配信、一斉送信機能を提供しているサービス4選
              </h2>
              <h3>twipla</h3>
              <a href="https://twipla.jp/dm" target="_blank" rel="noreferrer">
                twipla
              </a>
              <p>
                一斉送信が可能ですが、個別の内容を送ることができないため、プレゼントキャンペーンの結果通知などでは要求される仕様を満たさない場合が多いと思います。同じ内容を一斉に送るのであればこのツールで十分です。ログインするだけで無料で使えるようです。
              </p>
              <h3>twiDM</h3>
              <a
                href="https://www.wow-world.co.jp/release/000677.html"
                target="_blank"
                rel="noreferrer"
              >
                twiDM
              </a>
              <p>
                １件ずつ個別に配信する内容を変えて一斉送信する、という要件を満たしているのですが、サービス提供が終了してしまっているようです。再開の見込みはなさそうです。
              </p>
              <h3>TwiDesk</h3>
              <a
                href="https://twdesk.com/feature/bulksend/"
                target="_blank"
                rel="noreferrer"
              >
                TwiDesk
              </a>
              <p>
                管理機能の一部として提供されており、エンタープライズプラン以上を契約していれば利用可能です。ただし、月額で契約する必要があるため、利用してない月でも料金がかかってしまうのがネックです。毎月必ずキャンペーンを実施する予定のアカウントであれば検討の余地がありそうです。
              </p>
              <h3>PARKLoT</h3>
              <a
                href="https://www.park-lot.com"
                target="_blank"
                rel="noreferrer"
              >
                PARKLoT(パークロット)
              </a>
              <p>
                個別DMを配信できるツールを提供しています。法人のみ利用化。従量課金で利用が可能なので月額利用料は不要です。詳しくは営業担当へお問い合わせください。
              </p>
            </div>
            <div>
              <h2 id="id5">2. Twitter API を活用したプログラムを作成する</h2>
              <p>
                開発者として申請し、Twitter
                APIを利用して自分の手でプログラムを記述することもできます。
              </p>
              <p>
                ソフトウェア開発の専門的な知識が必要になりますが、社内に頼れる専門家がいる場合や、自分でプログラムを書くことができる場合にはオススメです。
              </p>
              <p>
                Twitterが提供している、Twitter API
                には、プログラムを使ってDMを送信するAPIが用意してあります。このAPIを使えば指定したユーザーに一斉にDMを送ることができます。
              </p>
              <p>
                ただし、どうしても専門的な知識が必要となるため、専門家以外ではTwitter
                API を利用するハードルが高いでしょう。
              </p>
            </div>
            <div>
              <h2 id="id6">
                【番外編】エクセルで送信相手のDMリンクを自動で開けるように関数を仕込む
              </h2>
              <p>
                DMの一斉送信を完全に自動化できるわけではありませんが、送信の手間を大幅に削減する方法をご紹介します。
              </p>
              <p>
                この方法を使ってDMを送る作業を簡易化する場合には、まず最初に、送信したいユーザーの
                <strong>ユーザーID</strong>
                を確認する必要があります。「ユーザー名」ではないため、ご注意ください。
              </p>
              <ul className="blog__border-list">
                <p>
                  <strong>
                    Twitterでは、「ユーザー名」と「ユーザーID」は異なる概念です。
                  </strong>
                </p>
                <p>
                  ユーザー名は「@」からはじまる文字列で英数字と記号から成り、任意の名前を設定できます。例えば「@abc_123」であれば、ユーザー名は「abc_123」です。ユーザー名は、後から変更することもできます。
                </p>
                <p>
                  一方、ユーザーIDは「0000000000」といった数字の羅列になります。これは、Twitterアカウント作成時に付与され、ユーザーが変更することはできません。
                </p>
                <p>
                  ユーザーIDは普通にTwitterを利用していても目にする機会が少ないため、自分のユーザーIDを認識していないユーザーも多いでしょう。
                </p>
              </ul>
              <p>
                ユーザーIDは、
                <a
                  href="https://tweeterid.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  twitterid.com
                </a>
                などのWebツールで確認することができます。
              </p>
              <h3>エクセルでDM画面にジャンプできるリンクを自動生成する</h3>
              <p>
                A列にユーザーIDを入力し、B列にエクセルの関数で「`=HYPERLINK("https://twitter.com/messages/compose/"&amp;A1)`」と入力するとDM画面にジャンプできるリンクが生成されるため、文面を少し変えてTwitterDMを送る場合に便利です。
              </p>
              <p>
                C列に、送信する文面が出力されるようにすれば、あとはリンクを開いてコピペして送信するだけで完結するので、出来る限り手作業でやりつつ、工数やミスを減らしたい方にオススメです。数十件程度であれば、20-30分で終わるのではないでしょうか。
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="ユーザIDとDM画面へのリンクを生成しているエクセルの画面"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-dm/twitter-dm-2.png"
              />
              <span className="blog__image-caption">
                エクセルでユーザIDとDM画面へのリンクを生成する例
              </span>
            </div>
            <div>
              <h2 id="id7">終わりに</h2>
              <p>
                Twitterキャンペーンは効果が高い一方で、キャンペーンの設計によっては非常に運営の手間がかかります。
              </p>
              <p>物理的な景品にするか、Webで完結するデジタルギフトにするか？</p>
              <p>景品に使用する最適な予算は？</p>
              <p>当選本数を何本にするか？</p>
              <p>バナーなどのクリエイティブはどうするか？</p>
              <p>リマインド投稿頻度はどのくらいで、何度くらいやるべきか？</p>
              <p>
                など、もしSNSを活用したキャンペーンの企画で迷ったら、PARKLoTへ是非
                <a
                  href="https://hub.park-lot.com/free-consulting"
                  target="_blank"
                  rel="noreferrer"
                >
                  ご相談（無料）
                </a>
                ください。
              </p>
            </div>
            <br />
            <HubSpotFormComponent />
            <br />
            <div className="blog__sns-container--foot">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* <div className="case_study main">
              <CvBtn />
            </div> */}
            <Author name="matsuda" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default TwitterDm
